import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {Avatar, Container} from "@mui/material"
import LogoEuPescador from '~assets/images/logo-eu-pescador.png'
import HouseIcon from '@mui/icons-material/House'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PeopleIcon from '@mui/icons-material/People'
import LogoutIcon from '@mui/icons-material/Logout'
import IconePeixe from '~assets/icons/icone-peixe.png'
import {useNavigate} from "react-router-dom"
import SettingsIcon from '@mui/icons-material/Settings'

export interface UserProps {
  admin: boolean
  name: string
  token: string
  superAdmin: boolean
}
const UserCard = () => {
  const user: UserProps = JSON.parse(
    localStorage.getItem('UserData')
  ) as UserProps
  return (
    <Box display="flex" sx={{alignItems: 'center', pr: 8}}>
      <Box sx={{color: '#3d4c76'}}>
        <Typography sx={{fontWeight: 'bold', fontSize: '18px', mr: 2, textAlign: 'right'}}>
          {user?.name ? user.name : 'Nome'}
        </Typography>
        <Typography sx={{fontWeight: 'bold', fontSize: '14px', mr: 2, textAlign: 'right'}}>
          {user?.admin ? 'Administrador' : 'Usuário'}
        </Typography>
      </Box>
      <Avatar
        data-testid="avatar"
        sx={{backgroundColor: '#3d4c76', color: '#ffffff'}}
      />
    </Box>
  )
}
export default function AdminHeader() {
  const navigate = useNavigate()

  const routeChange = () => {
    const path = '/login'
    navigate(path)
  }

  function clearUserData() {
    localStorage.clear()
  }

  function handleLogoutClick() {
    clearUserData()
    routeChange()
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '10%',
        width: '100%',
        fontSmooth: 'antialiased',
        backgroundColor: 'white',
        alignItems: 'center'
      }}>

      <Container sx={{
        display: 'flex'
      }}>
        <div
          style={{display: 'flex', justifyContent: 'flex-start', width: '50%', fontWeight: 'bold'}}>

          <Box sx={{fontWeight: 'bold', display:'flex', alignItems:'center'}}>
            <Button href="/"
                    sx={{flexDirection: 'column', textTransform: 'none', fontWeight: 'bold', color: '#3d4c76'}}>
              <HouseIcon/>
              <p>Home</p>
            </Button>
            <Button href="/dados"
                    sx={{flexDirection: 'column', textTransform: 'none', fontWeight: 'bold', color: '#3d4c76'}}>
              <img style={{height: '28px'}} src={IconePeixe} alt="Peixe"/>
              <p>Biblioteca de peixes</p>
            </Button>
            <Button href="/logs"
                    sx={{flexDirection: 'column', textTransform: 'none', fontWeight: 'bold', color: '#3d4c76'}}>
              <SettingsIcon/>
              <p>Logs</p>
            </Button>
            <Button href="/mapa"
                    sx={{flexDirection: 'column', textTransform: 'none', fontWeight: 'bold', color: '#3d4c76'}}>
              <LocationOnIcon/>
              <p>Mapa</p>
            </Button>

            <Button href="/usuarios"
                    sx={{flexDirection: 'column', textTransform: 'none', fontWeight: 'bold', color: '#3d4c76'}}>
              <PeopleIcon/>
              <p>Usuários</p>
            </Button>
            <Button onClick={handleLogoutClick}
                    sx={{flexDirection: 'column', textTransform: 'none', fontWeight: 'bold', color: '#3d4c76'}}>
              <LogoutIcon/>
              <p>Sair</p>
            </Button>

          </Box>

        </div>
        <div
          style={{display: 'flex', justifyContent: 'flex-end', width: '50%', flexWrap: 'nowrap'}}>

          <UserCard/>

          <Box
            sx={{
              m: 1,
            }}>
            <img style={{height: '50px'}} src={LogoEuPescador} alt="Logo Eu Pescador"/>
          </Box>

        </div>
      </Container>
    </div>

  )
}
