/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {Grid, Box, TextField, Typography, Button, Alert, FormControlLabel, Switch} from '@mui/material'
import {useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import Header, {UserProps} from '~components/Header'
import Sidebar from '~components/Sidebar'
import {TitlePage} from '~components/TitlePage/TitlePage'
import {GetOneFishLog} from '~services/api/fishLogServices/getOneFishLog'
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import '~assets/styles/DetailsButtons.css'
import {UpdateFishLog} from '~services/api/fishLogServices/updateFishLog'
import {toast} from 'react-toastify'
import {withStyles} from '@material-ui/core/styles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import AdminHeader from "~components/AdminHeader/adminHeader"
import GrandeGrupoPeixeIcon from "~assets/icons/GrandeGrupoPeixe.svg"
import PequenoGrupoPeixeIcon from "~assets/icons/PequenoGrupoPeixe.svg"

export interface FishLogProps {
  id: string
  coordenates: {
    latitude: number
    longitude: number
  }
  family: string
  group: string
  largeGroup: string
  name: string
  photo: string | null
  reviewed: boolean
  species: string
  length: string
  weight: string
  visible: boolean
}


export default function LogsDetails() {
  const [log, setLog] = useState({} as FishLogProps)
  const {id} = useParams()

  const getLog = async (logId: string) => {
    const user: UserProps = JSON.parse(
      localStorage.getItem('UserData')
    ) as UserProps
    const data = await GetOneFishLog(logId, user.token)
    setLog(data)
  }

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getLog(id)
    }
  }, [id])

  const atualizaLog = async () => {
    const user: UserProps = JSON.parse(
      localStorage.getItem('UserData')
    ) as UserProps
    await UpdateFishLog(
      id,
      log.name,
      log.largeGroup,
      log.group,
      log.species,
      log.coordenates.latitude ? log.coordenates.latitude.toString() : null,
      log.coordenates.longitude ? log.coordenates.longitude.toString() : null,
      log.photo,
      log.length,
      log.weight,
      true,
      user.token
    )
    toast.success('Log atualizado com sucesso!')
    await new Promise((resolve) => setTimeout(resolve, 2000))
    routeChange()
  }

  const navigate = useNavigate()
  const routeChange = () => {
    const path = '/logs'
    navigate(path)
  }

  const textField = {
    borderRadius: '4px',
    color: '#3d4c76',
    backgroundColor: '#ffffff',
    height: '30px'
  }

  const InputLabel = {
    color: '#3d4c76',
    fontWeight: 'bold',
    margin: '2px'
  }

  const Icon = {
    width: '30px',
    alignSelf: 'center'
  }
  return (
    <div style={{backgroundColor: '#d8d8d8', width:'100%'}}>
      <AdminHeader/>
      <Grid container xs={11}>
        <TitlePage title="Detalhes do Log"/>
        {log && Object.keys(log).length > 0 && (
          <Box sx={{mt: 5, ml: 5, display: 'flex'}}>
            <Box sx={{mt: 2, width: '28%'}}>

              <Box>
                <p style={InputLabel}>Nome</p>
                <TextField
                  margin="normal"
                  fullWidth
                  name="name"
                  value={log.name ? log.name : ''}
                  onChange={function (e) {
                    setLog({...log, name: e.target.value})
                  }}
                  InputLabelProps={{
                    style: {color: '#0095D9', fontWeight: 'bold'},
                  }}
                  InputProps={{
                    style: textField,
                  }}
                />
              </Box>
              <Box>
                <p style={InputLabel}>Grande Grupo</p>
                <TextField
                  margin="normal"
                  fullWidth
                  name="largeGroup"
                  value={log.largeGroup ? log.largeGroup : ''}
                  onChange={function (e) {
                    setLog({...log, largeGroup: e.target.value})
                  }}
                  InputLabelProps={{
                    style: {color: '#0095D9', fontWeight: 'bold'},
                  }}
                  InputProps={{
                    style: textField,
                  }}
                />
              </Box>
              <Box>
                <p style={InputLabel}>Grupo</p>
                <TextField
                  margin="normal"
                  fullWidth
                  name="group"
                  value={log.group ? log.group : ''}
                  onChange={function (e) {
                    setLog({...log, group: e.target.value})
                  }}
                  InputLabelProps={{
                    style: {color: '#0095D9', fontWeight: 'bold'},
                  }}
                  InputProps={{
                    style: textField,
                  }}
                />
              </Box>

              <Box>
                <p style={InputLabel}>Espécie</p>
                <TextField
                  margin="normal"
                  fullWidth
                  name="species"
                  value={log.species ? log.species : ''}
                  onChange={function (e) {
                    setLog({...log, species: e.target.value})
                  }}
                  InputLabelProps={{
                    style: {color: '#0095D9', fontWeight: 'bold'},
                  }}
                  InputProps={{
                    style: textField,

                  }}
                />
              </Box>

              <Box>
                <Box display='flex' flexDirection='row'>
                  <Box>
                    <p style={InputLabel}>Massa(g)</p>
                    <TextField
                      value={log.weight ? log.weight : ''}
                      onChange={function (e) {
                        setLog({...log, weight: e.target.value})
                      }}
                      sx={{mr: 4}}
                      InputLabelProps={{
                        style: {color: '#0095D9', fontWeight: 'bold'},
                      }}
                      InputProps={{
                        style: textField,

                      }}
                    />
                  </Box>
                  <Box>
                    <p style={InputLabel}>Tamanho(cm)</p>
                    <TextField
                      name="length"
                      value={log.length ? log.length : ''}
                      onChange={function (e) {
                        setLog({...log, length: e.target.value})
                      }}
                      InputLabelProps={{
                        style: {color: '#0095D9', fontWeight: 'bold'},
                      }}
                      InputProps={{
                        style: textField,

                      }}
                    />
                  </Box></Box>

              </Box>
              {/*<FormControlLabel
                                control={
                                    <Switch
                                        checked={log.visible}
                                        onChange={() => {
                                            setLog({...log, visible: !log.visible})
                                        }}
                                    />
                                }
                                label="Visível"
                            />*/}
              <FormControlLabel
                control={
                  <Switch
                    checked={log.visible}
                    onChange={() => {
                      setLog({...log, visible: !log.visible})
                    }}
                  />
                }
                label="Aprovar"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={log.visible}
                    onChange={() => {
                      setLog({...log, visible: !log.visible})
                    }}
                  />
                }
                label="Reprovar"
              />
              <Box sx={{display: 'flex', width: '50%'}}>
                <Button
                  onClick={routeChange}
                  variant="contained"
                  disableElevation
                  sx={{
                    margin: '2px',
                    backgroundColor: '#3d4c76',
                    borderRadius: '8px',
                    border: '2px solid white',
                    height: '50px',
                    width: '350px',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: '900'
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={atualizaLog}
                  variant="contained"
                  disableElevation
                  sx={{
                    margin: '2px',
                    backgroundColor: '#019055',
                    borderRadius: '8px',
                    border: '2px solid white',
                    height: '50px',
                    width: '350px',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: '900'
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Box>

            <Box sx={{display: 'flex'}}>
                {log.photo ? (
                  <Box sx={{bgcolor: '#FFF', borderRadius: '5%', width: '400px', height: '300px', margin: '15px'}}>
                    <Box sx={{bgcolor: '#d8d8d8', borderRadius: '5%'}}>
                      <img
                        src={`data:image/png;base64,${log.photo}`}
                        width={400}
                        height={200}
                        style={{borderRadius: '20px'}}
                      />
                      <Typography
                        gutterBottom
                        variant="h2"
                        component="div"
                        style={{
                          fontSize: '15px',
                          fontWeight: 'bold',
                          textOverflow: 'ellipsis',
                          color: '#3d4c76',
                          textAlign: 'center',
                          transform: 'translate(0px, -25px)'
                        }}
                      >
                        {log.name}
                      </Typography>

                    </Box>
                    <Box sx={{display: 'flex', margin: '8px'}}>
                      <Box display='flex' flexDirection='column' width="50%" margin="5px auto">
                        <img style={Icon} src={GrandeGrupoPeixeIcon} alt="GrandeGrupoPeixeIcon"/>
                        <label style={{color: '#3d4c76', fontWeight: 'bold', alignSelf: 'center'}}>Grande Grupo</label>
                        <p style={{color: '#3d4c76', fontSize: '13px', alignSelf: 'center'}}>{log.largeGroup ?? '---'}</p>
                      </Box>
                      <Box display='flex' flexDirection='column' width="50%" margin="5px auto">
                        <img style={Icon} src={PequenoGrupoPeixeIcon} alt="PequenoGrupoPeixeIcon"/>
                        <label style={{color: '#3d4c76', fontWeight: 'bold', alignSelf: 'center'}}>Grupo</label>
                        <p style={{color: '#3d4c76', fontSize: '13px', alignSelf: 'center'}}>{log.group ?? '---'}</p>
                      </Box>
                    </Box>

                  </Box>

                ) : (
                  <Alert severity="info">Opa, parece que este registro não possui uma foto.</Alert>
                )}

                <Box sx={{width: '400px', margin:'20px'}}>
                  <Typography
                    sx={{mt: 5, mb: 1, fontWeight: 'bold', color: '#3d4c76'}}>Localização</Typography>

                  {log.coordenates && log.coordenates.latitude && log.coordenates.longitude ? (
                    <MapContainer
                      center={[log.coordenates.latitude, log.coordenates.longitude]}
                      zoom={13}
                      scrollWheelZoom
                      style={{height: '40vh', borderRadius: '20px solid'}}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={[log.coordenates.latitude, log.coordenates.longitude]}>
                        <Popup>Esta é a localização do {log.name}</Popup>
                      </Marker>
                    </MapContainer>
                  ) : (
                    <Box sx={{display: 'flex'}}>
                      <Typography sx={{ml: 5, mt: 2, mb: 1, fontWeight: 'light'}}>Sem
                        Localização</Typography>
                    </Box>
                  )}
                </Box>
              </Box>

          </Box>
        )}
      </Grid>
    </div>
  )
}
