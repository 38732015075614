export const columns = [
  {
    label: 'Nome',
    value: 'name',
  },
  {
    label: 'Grande Grupo',
    value: 'largeGroup',
  },
  {
    label: 'Grupo',
    value: 'group',
  },
  {
    label: 'Espécies',
    value: 'species',
  },
  {
    label: 'Tamanho',
    value: 'length',
  },
  {
    label: 'Massa',
    value: 'weight',
  },
  {
    label: 'Visível',
    value: 'visible',
  },
]
