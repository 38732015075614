import {styled} from '@mui/material/styles'
import {Card} from "@mui/material"
import IconePeixe from '~assets/icons/icone-peixe.png'

type Fish = {
  name: string
  imageUrl: string
}

type FishCardProps = {
  fish: Fish
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '10%',
})

export function FishCard(props: FishCardProps) {
  return (
    <Card sx={{width: '200px', height: '180px', margin: '10px', borderRadius: '8px'}}>
      <Img alt="complex" src={props.fish.imageUrl} style={{width: '200px', height: '150px', borderRadius: '8px'}}/>
      <div style={{display: 'flex', alignItems: 'center', marginLeft: '4px'}}>
        <img style={{height: '30px'}} src={IconePeixe} alt="Peixe"/>
        <p style={{color: '#3d4c76', fontWeight: 'bold', fontSize: '13px', marginLeft: '10px'}}>{props.fish.name}
        </p>
      </div>

    </Card>
  )
}
