import logoFooter from '~assets/images/Naturatins.png'
export default function Footer() {

  return (
    <div
      /*TODO obter imagens com resolução melhor*/
    style={{display: 'flex', height: '10%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
      <img style={{height: '70px', alignSelf:'flex-end'}} src={logoFooter} alt="Naturatins e GovTocantins"/>
    </div>
  )
}
