/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-types */
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

interface RowProp {
  [key: string]: string | number
}

interface ColumnProp {
  label: string
  value: string
}

interface TableProps {
  columns: ColumnProp[]
  rows: RowProp[]
  onDelete?: (row: RowProp) => void
  onEdit?: (row: RowProp) => void
}

export default function TableComponent({rows, columns, onDelete, onEdit}: TableProps) {
  const result = rows.map((row) => {
    const display = columns.map((column) => row[column.value])
    return display
  })

  return (
    <>
      <TableContainer
        sx={{
          minWidth: 650,
          width: "95%",
          '& .MuiTableRow-root': {
            backgroundColor: 'white',
          },
          fontWeight: 700,
          '& .MuiTableCell-body': {
            color: '#000000',
            borderBottom: "solid 2px #e8e8e8"
          },
          '& .MuiTableCell-head': {
            fontWeight: 600,
            fontSize: '1rem',
            color: '#3d4c76',
            borderBottom: "solid 2px #e8e8e8"
          },
          '& .MuiTableHead-root': {
            borderBottom: "solid 2px #e8e8e8"
          }
        }}
      >
        <Table
          sx={{
            minWidth: 650
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow style={{backgroundColor: '#e8e8e8'}}>
              {columns.map((column) => (
                <TableCell key={column.label} component="th">
                  {column.label}
                </TableCell>
              ))}
              {onEdit && <TableCell component="th">Editar</TableCell>}
              {onDelete && <TableCell component="th">Deletar</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {result.map((row, index) => (
              <TableRow key={index} data-testid={`rowTable-${index}`}
                        style={{backgroundColor: index % 2 === 0 ? '#ffffff' : '#e8e8e8'}}>
                {row.map((data, index) => (
                  <TableCell key={index}>{data}</TableCell>
                ))}
                {onEdit && (
                  <TableCell>
                    <IconButton onClick={() => onEdit(rows[index])}>
                      <EditIcon color="success"/>
                    </IconButton>
                  </TableCell>
                )}
                {onDelete && (
                  <TableCell>
                    <IconButton data-testid="deleteButton" onClick={() => onDelete(rows[index])}>
                      <DeleteIcon color="error"/>
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
