import {useState} from 'react'
import Box from '@mui/material/Box'
import {Typography, Button, Modal, TextField} from '@mui/material'
import IconePeixeLaranja from '~assets/icons/peixe-laranja.png'
import {FishRecord} from '../../components/FishRecord/FishRecord'
import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from '@mui/icons-material/Search'

interface HeaderProps {
  title: string
  button?: boolean
}

export function TitlePage({title = 'Titulo', button = false}: HeaderProps) {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleOpenFishModal = () => {
    handleOpen()
  }

  return (

    <Box
      sx={{
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        mb: '30px',
        mt: '30px',
        width: '100%'
      }}
    >
      <Typography sx={{width: '50%',pl: 2, fontWeight: 'bold', fontSize: '30px', color: '#3d4c76'}}>{title}</Typography>

      {button && (
        <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            variant="text"
            onClick={() => {
              handleOpenFishModal()
            }}
            sx={{
              borderRadius: '20px',
              fontWeight: 'bold',
              marginLeft: 'auto',
              marginRight: '16px',
              color: '#de8c3e',
              textTransform: 'none',
              fontSize: '20px'
            }}
          >
            <img style={{height: '30px'}} src={IconePeixeLaranja} alt="Peixe"/>
            Cadastrar Espécie
          </Button>
          <TextField
            placeholder="Pesquisar"
            className="textfield"
            margin="normal"
            id="pesquisar"
            name="pesquisar"
            autoFocus
            sx={{
              fieldset: {borderColor: "#ffffff"}
            }}
            InputLabelProps={{
              style: {color: '#111111'},
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{color: '#3d4c76'}}/>
                </InputAdornment>
              ),
              style: {
                borderRadius: '8px',
                color: '#3d4c76',
                backgroundColor: '#ffffff'
              },
            }}
          />
        </div>
      )}
      <Modal id="fishRecordModalBackground" open={open} onClose={handleClose}>
        <Box>
          <FishRecord onClose={handleClose}/>
        </Box>
      </Modal>
    </Box>
  )
}
