import wikiService  from './wikiServices/wikiService'


export interface ResI {
  data: []
  status: number
}

export interface FishWiki {
  id?: string
  largeGroup?: string
  group?: string
  commonName?: string
  scientificName?: string
  family?: string
  food?: string
  habitat?: string
  maxSize?: string
  maxWeight?: string
  isEndemicInfo?: string
  isEndemic?: boolean
  isThreatenedInfo?: string
  isThreatened?: boolean
  hasSpawningSeasonInfo?: string
  hasSpawningSeason?: boolean
  wasIntroducedInfo?: string
  wasIntroduced?: boolean
  funFact?: string
  photo?: string
}

export interface FishWikiArray {
  allFishWiki? : FishWiki[]
  page?: number
  count?: number
  totalPages?: number
}

export async function GetAllFishes(page: number, count: number, search: string) {
  const res: ResI = await wikiService.get(`/fishWiki?page=${page}&count=${count}&search=${search}`)
  return res.data  as FishWikiArray
}
