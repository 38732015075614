import {MouseEventHandler, useState} from 'react'
import {
    Box,
    Button,
    Typography,
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import {styled} from '@mui/material/styles'


import {FishWiki} from '../../services/api/interfaces'
import {DeleteWikiFish} from '../../services/api/wikiServices/deleteWikiFish'
import {FishRecord} from '../../components/FishRecord/FishRecord'
import {toast, ToastOptions} from 'react-toastify'
import GrandeGrupoPeixeIcon from '~assets/icons/GrandeGrupoPeixe.svg'
import PequenoGrupoPeixeIcon from '~assets/icons/PequenoGrupoPeixe.svg'

type FishModalProps = {
    onClose?: MouseEventHandler<HTMLSpanElement>
    fish: FishWiki
}

const estiloTabela = {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 500,
    bgcolor: '#e8e8e8',
    border: '2px solid #de8c3e',
    borderRadius: 4,
    boxShadow: 24,
    paddingLeft: 3,
    paddingTop: 4,
    paddingRight: 3,
    paddingBottom: 3,
}

const Img = styled('img')({
    borderRadius: '10%',
    border: '3px solid #de8c3e',
    height: '200px',
    transform: 'translate(0px, -20%)'
})

const Icon = {
    width: '30px',
    alignSelf: 'center'
}

export function FishModal(props: FishModalProps) {
    const [open, setOpen] = useState(false)
    const [modalFish, setModalFish] = useState({} as FishWiki)
    const handleClose = () => setOpen(false)
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClickOpen = () => {
        setOpenDialog(true)
    }

    const handleOpenFishModal = () => {
        setModalFish(props.fish)
        handleOpen()
    }

    const handleClickClose = () => {
        setOpenDialog(false)
    }

    const toastConfig = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    } as ToastOptions

    const handleDelete = async () => {
        await DeleteWikiFish(props.fish.id)
            .then(async () => {
                toast.success('Peixe excluído com sucesso')
                await new Promise((resolve) => setTimeout(resolve, 2000))
                window.location.reload()
            })
            .catch(() => {
                toast.error('Erro ao excluir peixe', toastConfig)
            })
        handleClickClose()
    }

    return (
        <>
            <Box flexWrap="wrap" overflow="hidden" justifyContent="space-between" alignItems='center'
                 alignContent='center'
                 sx={estiloTabela} id="fishEditModal">
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    alignContent="center"
                    flexBasis="200px"
                    textAlign="center"
                >
                    <Box sx={{bgcolor: '#FFF', borderRadius: '5%', width: '400px', marginLeft: '50px'}}>
                        <Box sx={{bgcolor: '#d8d8d8', borderRadius: '5%'}}>
                            <Img
                                alt="complex"
                                id="fishImage"
                                src={props.fish.photo == null ? 'https://imgur.com/ybTpCh6.png' : props.fish.photo}
                            />

                            <Typography
                                gutterBottom
                                variant="h2"
                                component="div"
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    textOverflow: 'ellipsis',
                                    color: '#3d4c76',
                                    textAlign: 'center',
                                    transform: 'translate(0px, -25px)'
                                }}
                            >
                                {props.fish.commonName}, {props.fish.scientificName}
                            </Typography>

                        </Box>
                        <Box sx={{display: 'flex', margin: '15px'}}>
                            <Box display='flex' flexDirection='column' width="50%" margin="5px auto">
                                <img style={Icon} src={GrandeGrupoPeixeIcon} alt="GrandeGrupoPeixeIcon"/>
                                <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Grande Grupo</label>
                                <p style={{color: '#3d4c76', fontSize: '13px'}}>{props.fish.largeGroup ?? '---'}</p>
                            </Box>
                            <Box display='flex' flexDirection='column' width="50%" margin="5px auto">
                                <img style={Icon} src={PequenoGrupoPeixeIcon} alt="PequenoGrupoPeixeIcon"/>
                                <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Grupo</label>
                                <p style={{color: '#3d4c76', fontSize: '13px'}}>{props.fish.group ?? '---'}</p>
                            </Box>
                        </Box>

                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'center'}}>

                        <Button
                            onClick={handleOpenFishModal}
                            variant="contained"
                            sx={{
                                backgroundColor: '#3d4c76',
                                borderRadius: '5px',
                                height: '4 0px',
                                width: '200px',
                                textTransform: 'capitalize',
                                fontSize: '18px',
                                fontWeight: '900',
                                alignSelf: 'center'
                            }}
                        >
                            Editar Espécie

                        </Button>
                    </Box>

                </Box>

                <Box
                    width="400px"
                    style={{
                        padding: '2px',
                    }}
                >
                    <Box
                        width="100%"
                        style={{
                            padding: '3px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderRadius: '8px',
                            gap: '20px',
                            paddingLeft: '8px',
                        }}
                    >
                    </Box>

                    <Box
                        width="100%"
                        style={{
                            padding: '3px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderRadius: '8px',
                            gap: '20px',
                            paddingLeft: '8px',
                        }}
                    >
                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Família</label>
                            <p>{props.fish.family ?? '---'}</p>
                        </Box>

                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Alimentação</label>
                            <p>{props.fish.food ?? '---'}</p>
                        </Box>
                    </Box>

                    <Box
                        width="100%"
                        style={{
                            padding: '3px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderRadius: '8px',
                            gap: '20px',
                            paddingLeft: '8px',
                        }}
                    >
                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Tamanho Máx(cm)</label>
                            <p>{props.fish.maxSize ?? '---'}</p>
                        </Box>

                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Peso Máx(kg)</label>
                            <p>{props.fish.maxWeight ?? '---'}</p>
                        </Box>
                    </Box>

                    <Box
                        width="100%"
                        style={{
                            padding: '3px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderRadius: '8px',
                            gap: '20px',
                            paddingLeft: '8px',
                        }}
                    >
                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Habitat</label>
                            <p>{props.fish.habitat ?? '---'}</p>
                        </Box>

                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Endêmico ?</label>
                            <p>{props.fish.isEndemic ? 'Sim' : 'Não' ?? '---'}</p>
                        </Box>
                    </Box>

                    <Box
                        width="100%"
                        style={{
                            padding: '3px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            borderRadius: '8px',
                            gap: '20px',
                            paddingLeft: '8px',
                        }}
                    >
                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Ameaçado ?</label>
                            <p>{props.fish.isThreatened ? 'Sim' : 'Não' ?? '---'}</p>
                        </Box>

                        <Box width="50%" margin="5px auto">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Faz Piracema ?</label>
                            <p>{props.fish.hasSpawningSeason ? 'Sim' : 'Não' ?? '---'}</p>
                        </Box>
                    </Box>

                    <Box
                        width="100%"
                        style={{
                            padding: '3px',
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '8px',
                            gap: '20px',
                            paddingLeft: '8px',
                        }}
                    >
                        <Box width="50%" margin="0px" paddingTop="5px">
                            <label style={{color: '#3d4c76', fontWeight: 'bold'}}>Foi introduzido ?</label>
                            <p>{props.fish.wasIntroduced ? 'Sim' : 'Não' ?? '---'}</p>
                        </Box>
                    </Box>
                </Box>

                <span
                    onClick={props.onClose}
                    style={{
                        color: '#3d4c76',
                        cursor: 'pointer',
                        position: 'absolute',
                        left: '95%',
                        bottom: '93%',
                    }}
                >
          &#10006;
        </span>
            </Box>
            <Modal open={open} onClose={handleClose}>
                <Box>
                    <FishRecord fish={modalFish} edit onClose={handleClose}/>
                </Box>
            </Modal>
            <Dialog
                open={openDialog}
                onClose={handleClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Deseja excluir essa espécie de peixe?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Clique em confirmar para prosseguir com a exclusão do registro
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose}>Cancelar</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
